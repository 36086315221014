import "../pages.css";
import "./About.css";
import { LinkSection } from "../../components/LinkSection/LinkSection";
import { Separator } from "../../components/Separator/Separator";
import ResumeButton from "../../components/ResumeButton/ResumeButton";

export default function About() {
  return (
    <div className="PageBody">
      <div className="AboutContainer">
        <h1 className="PageHeader">Hello 👋</h1>
        <p className="PageText">
          <p>
            My name is Joachim, and I am a passionate software engineer
            specializing in full stack development. Currently, I'm proud to be
            working at YouCruit, where I contribute my expertise building
            innovative and robust software solutions.
          </p>
          <p>
            I completed my education in Computer Science and Engineering at Lund
            University from 2015 to 2019, with a specialization in Computer
            Software.
          </p>
          <p>
            During my time at Lund University, I acquired a strong foundation in
            software development principles and practices. The program provided
            me with a comprehensive understanding of algorithms, data
            structures, software design, and system architecture. Additionally,
            I had the opportunity to explore various programming languages and
            development methodologies, enabling me to develop a well-rounded
            skill set.
          </p>
          <p>
            In addition to my educational background, I have several years of
            industry experience, including my previous position as a Compiler
            Developer at Modelon. At Modelon, I successfully added new features
            to the compiler and optimized its performance, resulting in enhanced
            overall efficiency.
          </p>
          <p>
            I'm proficient in programming languages such as TypeScript, Kotlin,
            and Java, I have hands-on experience with technologies like React,
            Vue.js, Spring, and PostgreSQL. I also have some experience working
            with Jenkins, Docker, Gradle, further expanding my technical
            toolkit.
          </p>
          <p>
            A personal project that I'm particularly proud of is{" "}
            <a className="Primary-link" href="https://nordicsurfing.com">
              Nordic Surfing
            </a>
            , a kitesurfing marketplace that I built from the ground up in my
            spare time. The site enables users to buy and sell equipment and
            chat with other users. The tech stack for the project includes
            Kotlin, Spring, PostgreSQL, TypeScript, React, Heroku, Google
            Analytics, and Cloudflare. Some of the key features include user
            authentication, real-time messaging, and user-generated content.
          </p>
          <p>
            I am deeply passionate about full stack development as it allows me
            to combine my creativity and problem-solving skills to build robust
            and user-friendly applications. The ever-evolving nature of the
            field fuels my desire for continuous learning and personal growth.
          </p>
          <p>Thanks for taking the time to check out my site!</p>
        </p>
        <ResumeButton />
        <div className="LinkContainer">
          <LinkSection />
          <Separator />
        </div>
      </div>
    </div>
  );
}
