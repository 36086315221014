import "./Footer.css";

export const Footer = () => {
  return (
    <div className="Footer">
      <p>© 2023. All Rights Reserved to Joachim Wedin.</p>
    </div>
  )
}

