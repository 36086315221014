import "../pages.css";
import "../../App.css";
import "./Home.css";
import { LanefinderLink } from "../../components/LanefinderLink/LanefinderLink";
import { Separator } from "../../components/Separator/Separator";
import { LinkSection } from "../../components/LinkSection/LinkSection";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="PageBody">
      <div className="HomeContainer">
        <div className="BodyLeft">
          <h1 className="PageHeader">Hello, I'm Joachim 👋</h1>
          <p className="PageSubHeader">Software Engineer at YouCruit.</p>
          <p className="PageText">
            I'm currently dedicated to revolutionizing the American truck
            industry at Youcruit, where I contribute my expertise in full stack
            development to create innovative and robust software solutions.
            <br />
            <br />
            I thrive on solving complex challenges and have a deep passion for
            crafting seamless user experiences through both front-end and
            back-end development.
            <br />
            <br />
            <Link className="Primary-link" to={"/about"}>
              About me
            </Link>
          </p>
        </div>
        <div className="BodyRight">
          <img
            src={require("../../images/me.jpg")}
            alt=""
            className="GreetingImage"
          />
        </div>
      </div>
      <LinkSection />
      <Separator />
    </div>
  );
}
