import "./Header.css";
import { NavLink } from "react-router-dom";

export const Header = () => {
  return (
    <div className="Header">
      <NavLink to="/" className="Header-left">Joachim Wedin</NavLink>
      <div className="Header-right">
        {/* <NavLink to="/work" className="Header-right-item">Work</NavLink> */}
        <NavLink to="/about" className="Header-right-item">About</NavLink>
      </div>
    </div>
  );
};