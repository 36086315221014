import './ResumeButton.css'
import Pdf from '../../pdf/resume.pdf'

export default function ResumeButton() {
    return (
        <a 
          href={Pdf}
          target="_blank"
          rel="noreferrer"
          className="ResumeButton"
        >
          <p className="Label">Resume</p>
        </a>
    )
}