import "./LinkSection.css";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LinkSection = () => {
  return (
    <div className="LinkSection">
      <a
        href="https://www.linkedin.com/in/joachimwedin/"
        target="_blank"
        rel="noreferrer"
        className="SocialIconWrapper"
      >
        <img
          src={require("../../icons/linkedin.png")}
          alt=""
          className="SocialIcon"
        />
      </a>
      <a
        href="https://github.com/joachimwedin"
        target="_blank"
        rel="noreferrer"
        className="SocialIconWrapper"
      >
        <img
          src={require("../../icons/github.png")}
          alt=""
          className="SocialIcon"
        />
      </a>
      <a
        href="https://stackoverflow.com/users/4862855/joachimwedin"
        target="_blank"
        rel="noreferrer"
        className="SocialIconWrapper"
      >
        <img
          src={require("../../icons/stack-overflow.png")}
          alt=""
          className="SocialIcon"
        />
      </a>
      <a
        href="mailto:joachim.wedin@gmail.com"
        target="_blank"
        rel="noreferrer"
        className="SocialIconWrapper"
      >
        <FontAwesomeIcon
          icon={faEnvelope}
          className="SocialIcon EnvelopeIcon"
        />
      </a>
    </div>
  );
}